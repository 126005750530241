import { Center, Text } from "@chakra-ui/react";
import React from "react";
import { ReactSVG } from "react-svg";

import { defaultTheme } from "@styles";
import { labelFontSize, smallFontSize } from "@styles/constants";

import { IProps } from "./types";

export const MenuIcon: React.FC<IProps> = ({
  dataTest,
  onClick,
  svgSrc,
  visible = true,
  textOverlay,
  iconWidth = "1.438rem",
}) => (
  <Center
    data-test={dataTest}
    onClick={() => onClick?.()}
    cursor="pointer"
    paddingRight={{ base: "1rem", xl: "0.5rem" }}
    display={visible ? "block" : "none"}
    _last={{ paddingRight: 0 }}
    height="100%"
  >
    <Center position="relative" height="18px">
      <ReactSVG src={svgSrc} style={{ width: iconWidth }} />
      {textOverlay && (
        <Text
          position="absolute"
          right={0}
          bottom={0}
          top="2px"
          left={0}
          display="inline-block"
          width={{ base: "15px", xl: "18px" }}
          height={{ base: "15px", xl: "18px" }}
          borderRadius="50%"
          margin="0 auto"
          backgroundColor={defaultTheme.colors.primary[500]}
          color={defaultTheme.colors.white}
          fontSize={{ base: labelFontSize, xl: smallFontSize }}
          lineHeight={{ base: "15px", xl: "18px" }}
          textAlign="center"
        >
          {textOverlay}
        </Text>
      )}
    </Center>
  </Center>
);

export default MenuIcon;
